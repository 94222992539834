import React from "react"
import {
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import styled from "styled-components"
import line from "../../images/horizontal-line.png"
import lineNeon from "../../images/horizontal-line-neon.png"

const Wrapper = styled.div`    
    .nav-tabs {
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: center;
        border: none;
    }
    
    .nav-item {
        padding: 0.5rem;
    }
    
    .nav-link {
        position: relative;
        border: none;
        opacity: 0.5;
        transition: opacity 0.2s linear;
        cursor: pointer;
        
        &.active, &:hover, &:focus {
            opacity: 1;
        }
        
        &.active {
            border: none;
            background: none;
            color: #ffffff;
        }
        
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: url("${line}") no-repeat center center;
            background-size: 100%;
            z-index: 2;
        }
        
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background: url("${lineNeon}") no-repeat center center;
            background-size: 100%;
            z-index: 3;
            opacity: 0;
            transition: opacity 0.2s linear;
        }
        
        &:hover, &:focus {
            border: none;
        }
        
        &:hover::before, &:focus::before {
            opacity: 1;
        }
    }
    
`

const Tabs = (props) => {
    // const [activeTab, setActiveTab] = useState(props.activeTab);

    function scrollToTop() {
        console.log('clicked')
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Wrapper>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({active: props.activeTab === '1'})}
                        onClick={() => {
                            props.parentCallback('1');
                            // setActiveTab('1');
                            scrollToTop();
                        }}
                    >
                        INTERNATIONAL SUCCESS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: props.activeTab === '2'})}
                        onClick={() => {
                            props.parentCallback('2');
                            // setActiveTab('2');
                            scrollToTop();
                        }}
                    >
                        ABOUT THE PLAY
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: props.activeTab === '3'})}
                        onClick={() => {
                            props.parentCallback('3');
                            // setActiveTab('3');
                            scrollToTop();
                        }}
                    >
                        PRODUCTION DESIGN
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: props.activeTab === '4'})}
                        onClick={() => {
                            props.parentCallback('4');
                            // setActiveTab('4');
                            scrollToTop();
                        }}
                    >
                        LICENSING AND MATERIALS
                    </NavLink>
                </NavItem>
            </Nav>
        </Wrapper>
    );
}

export default Tabs;