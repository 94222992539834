import React, {Component, useState} from "react"
import {
    TabContent,
    TabPane,
    Container
} from 'reactstrap';
import Layout from "../components/Layout/Layout";
import SEO from "utils/Seo"
import Footer from "../components/Layout/Footer";
import Tabs from "../components/shared/Tabs";
import InternationalSuccess from "../components/shared/InternationalSuccess";
import AboutThePlay from "../components/shared/AboutThePlay";
import ProductionDesign from "../components/shared/ProductionDesign";
import MarketingMaterials from "../components/shared/MarketingMaterials";
import styled from "styled-components"
import {graphql, StaticQuery, Link} from "gatsby";
import Img from "gatsby-image"
import {media} from "utils/Media";

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
`
const Header = styled.div`
    padding: 1rem 0.5rem;
    position: relative;
    z-index: 2;
    
    a {
        font-size: 20px;
        padding: 5px 15px;
        text-transform: uppercase;
        border: 2px solid white;
        text-decoration: none;
        color: white;
        
        &:hover, &:focus {
            color: white;
        }
    }
`
const Bg = styled.div`
    position: absolute;
    top: 0;
    left: -25%;
    z-index: 1;
    width: 150%;
    
    @media ${media.md} {
        transform: translateX(-50%);
        left: 50%;
        width: 100%;
    }
`
const Content = styled.div`
    position: relative;
    z-index: 2;
`
const Menu = styled.div`
    padding: 3rem 0;
    text-align: center;
`

class AdditionalInformation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1'
        }

        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        if(typeof window !== "undefined" && window.location.hash) {
            let activeTab
            const hash = window.location.hash.replace("/", "")
            console.log(hash)
            switch (hash) {
                case "#international-success":
                    activeTab = '1'
                    break;
                case "#about-the-play":
                    activeTab = '2'
                    break;
                case "#production-design":
                    activeTab = '3'
                    break;
                case "#licensing-and-materials":
                    activeTab = '4'
                    break;
            
                default:
                    activeTab = '1'
                    break;
            }
            this.setState({
                activeTab
            })
        }
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
        let activeTab
        switch (tab) {
            case "1":
                activeTab = '#international-success'
                break;
            case "2":
                activeTab = '#about-the-play'
                break;
            case "3":
                activeTab = '#production-design'
                break;
            case "4":
                activeTab = '#licensing-and-materials'
                break;

            default:
                break;
        }
        window.location.hash = activeTab
    }

    render() {
        return (
            <Layout scrollOff={true}>
                <SEO title="Additional Information"/>
                <Wrapper>
                    <Container fluid={true}>
                        <Container className="position-relative">
                            <Bg>
                                <Img fluid={this.props.data.woodBg.childImageSharp.fluid}/>
                            </Bg>
                        </Container>
                    </Container>
                    <Header>
                        <Link to="/">Home</Link>
                    </Header>
                    <Content>
                        <Container fluid={true}>
                            <Container>
                                <Menu>
                                    <h1 className="text-center text-uppercase">Additional Information</h1>
                                    <Tabs parentCallback={this.toggle} activeTab={this.state.activeTab} />
                                </Menu>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <InternationalSuccess/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <AboutThePlay/>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <ProductionDesign/>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <MarketingMaterials/>
                                    </TabPane>
                                </TabContent>
                                <Footer/>
                            </Container>
                        </Container>
                    </Content>
                </Wrapper>
            </Layout>
        )
    }
}
export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <AdditionalInformation data={data}/>
        )}
    />
)