import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media";
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

const Wrapper = styled.div`
`

const Flex = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const FlexItem = styled.div`
    width: 100%;
    padding: 1rem 0;

    @media ${media.md} {
        padding: 0 1rem;
        width: 50%;
    }
`
const StylePara = styled.div`
  strong {
    color: #ffaa01;
  }
`
class MarketingMaterials extends Component {
    render() {
        return (
            <Wrapper>
                <div className="narrow mx-auto py-4 py-md-5">
                    <Flex>
                        <FlexItem>
                            <Img fluid={this.props.data.haveYouDunnit.childImageSharp.fluid}/>
                        </FlexItem>
                        <FlexItem>
                            <p>We have recently undergone a stylish re-branding for The Mousetrap in the West End, and invite you to use our new marketing materials, should you so choose. Once agreement is in place we will share a link to layered artwork that you can use to suite your needs.</p>

                            <p>Please see an example of the assets available to licensed productions.</p>
                        </FlexItem>
                    </Flex>
                    <p>&nbsp;</p>
                    <h3>INTRO TO LICENSING</h3>

                    <p>Licensing a play or musical for performance is an important part of the process of making theatre. When you license a show, you are providing a living wage for writers and helping safeguard their work.</p>

                    <p>Some territories may be licensed automatically, with no restrictions. Other territories require additional conversation and may be restricted. Also, the Licensing fees often vary from title to title. These are also based on the category of your performing group be that Amateur, Education or Professional.</p>

                    <p>Below, you&apos;ll find a few frequently asked questions to help you get started and a list of contacts for your territory.</p>

                    <p><strong>Do I need a license?</strong></p>

                    <p>Any performance of a work under copyright for an audience must be licensed, regardless of whether or not admission is charged, whether the performance is public or private, and whether or not it is for charity or gain.</p>

                    <p><strong>Why do I need to license my production?</strong></p>

                    <p>Licensing your shows ensures that playwrights and composers are being fairly compensated for the use of their work.</p>

                    <p><strong>How do I get a license?</strong></p>

                    <p>You may apply online via any title page. Please remember that your License Agreement is not executed until we have received full payment of the licensing fees. Purchase Orders are not considered payment.</p>

                    <p>For a detailed walkthrough of the licensing process for North America, visit the Concord Help Site (<a href="https://help.concordtheatricals.com" target="_blank" rel="noreferrer noopener">https://help.concordtheatricals.com</a> ). Their team of Licensing Representatives are also available to assist every step of the way.</p>
                    <StylePara>
                    <h3>Licensing partners by territory</h3>
                    <p>For more information in your territory please contact.</p>

                        <p><strong>Concord Theatricals</strong><br/>
                    US and Canada Professional and Amateur licensing<br/>
                        <a href="https://www.concordtheatricals.com/p/898/the-mousetrap" target="_blank" rel="noreferrer noopener">https://www.concordtheatricals.com/p/898/the-mousetrap</a></p>

                        <p>
                            Europe Professional and Amateur licensing<br/>
                            <a href="https://www.concordtheatricals.co.uk/p/11367/the-mousetrap" target="_blank" rel="noreferrer noopener">https://www.concordtheatricals.co.uk/p/11367/the-mousetrap</a>
                        </p>
                        
                        <p>
                            Australia Professional and Amateur licensing<br/>
                            <a href="https://www.concordtheatricals.co.uk/p/11367/the-mousetrap" target="_blank" rel="noreferrer noopener">https://www.concordtheatricals.co.uk/p/11367/the-mousetrap</a>
                        </p>
                        
                        <p>
                            Rest of World Professional and Amateur licensing<br/>
                            <a href="https://www.concordtheatricals.com/p/898/the-mousetrap" target="_blank" rel="noreferrer noopener">https://www.concordtheatricals.com/p/898/the-mousetrap</a>
                        </p>

                        {/* <p><strong>Australia</strong><br/>
                        Agent: Origin Theatrical<br/>
                        Contact: <a href="mailto:enquiries@originmusic.com.au">enquiries@originmusic.com.au</a><br/>
                            <a href="https://www.origintheatrical.com.au/work/9337" target="_blank" rel="noreferrer noopener">https://www.origintheatrical.com.au/work/9337</a><br/>
                            PO Box Q1235, QVB Post Office, Sydney, NSW 1230</p>

                        <p><strong>New Zealand</strong><br/>
                        Agent: Play Bureau<br/>
                            Contact: <a href="mailto:info@playbureau.com">info@playbureau.com</a><br/>
                            <a href="https://www.playbureau.com/" target="_blank" rel="noreferrer noopener">https://www.playbureau.com/</a><br/>
                            Address PO Box 9013, Dunedin 9047,New Zealand</p>

                    <p><strong>Belgium/ France</strong><br/>
                    Agent: Bureau Litteraire International<br/>
                    Contact: Pascale Paugam - <a href="mailto:bureaulitteraireinternational@gmail.com">bureaulitteraireinternational@gmail.com</a> <br/>
                        Address: 15, boulevard Jules Ferry, F-01 000 Bourg-en-Bresse, France</p>

                    <p><strong>Czech Republic</strong><br/>
                    Agent: Dilia<br/>
                    Contact: Martin Kaňovský - <a href="mailto:kanovsky@dilia.cz">kanovsky@dilia.cz</a> <br/>
                        Address: DILIA, divadelní, literární, audiovizuální agentura, z.s. Krátkého 143/1, 190 03 Prague 9, Czech Republic</p>

                    <p><strong>Denmark/Finland/Iceland/Norway/Sweden</strong><br/>
                    Agent: Nordiska ApS International Performing Rights Agency<br/>
                    Contact: Jytte Due - <a href="mailto:jd@nordiska.dk">jd@nordiska.dk</a> <br/>
                        Address: Studiestræde 5, 1st floor, 1455 Copenhagen K, Denmark</p>

                    <p><strong>Germany</strong><br/>
                    Agent: Vertriebstelle GmbH<br/>
                    Contact: Wolfgang Neruda - <a href="mailto:wolfgang.neruda@vvb.de">wolfgang.neruda@vvb.de</a> <br/>
                        Address: Buchweizenkoppel 19, 22844 Norderstedt, Germany</p>

                    <p><strong>Greece/ Cyprus</strong><br/>
                    Agent: SOPE Copyright Protection Society<br/>
                    Contact: Evita Kourpa - <a href="mailto:theatre@theartbassador.gr">theatre@theartbassador.gr</a> <br/>
                        Address: Thisseos 13, GR 15124, Mouroussi, Athens, Greece</p>

                    <p><strong>Hungary</strong><br/>
                    Agent: HoFra - Theatrical and Literary Agency<br/>
                    Contact: Zsófia Csurgai - <a href="mailto:zsofia@hofra.hu">zsofia@hofra.hu</a> <br/>
                        Address: 1056 Budapest, Szerb utca 17. 1.em.4., Hungary</p>

                    <p><strong>Italy</strong><br/>
                    Agent: Arcadia & Ricorno Ltd<br/>
                    Contact: Anna Ashton Parnanzini - <a href="mailto:anna@arcadia-media.net">anna@arcadia-media.net</a> <br/>
                        Address: Suite 131, 22 Notting Hill Gate, London W11 3JE</p>

                    <p><strong>Netherlands</strong><br/>
                    Agent: TDI-AGENCY<br/>
                    Contact: Ernst M. van den Berg - <a href="mailto:tdi.agency@planet.nl">tdi.agency@planet.nl</a> <br/>
                        Address: Kinderdijkstraat 8, 1079 GH  Amsterdam, The Netherlands</p>

                    <p><strong>Slovakia</strong><br/>
                    Agent: LITA, Society of Authors<br/>
                    Contact: Lubica Visnovska - <a href="mailto:visnovska@lita.sk">visnovska@lita.sk</a> <br/>
                        Address: Mozartova 9, 811 02 Bratislava, Slovakia</p>

                    <p><strong>Spain</strong><br/>
                    Agent: International Editors Co.<br/>
                    Contact: Amaiur Fernández - <a href="mailto:amaiur.fernandez@internationaleditors.com">amaiur.fernandez@internationaleditors.com</a> <br/>
                        Address: Còrsega 288, 1r 2a – 08008 Barcelona - Spain</p>

                    <p><strong>Turkey</strong><br/>
                    Agent: ONK Agency<br/>
                    Contact: Aslihan Gulay - <a href="mailto:asli@onkagency.com">asli@onkagency.com</a> <br/>
                        Address: Turunç sokak Açar villaları No:10, Tarabya, 34457 İstanbul, Turkey</p>

                    <p><strong>Ukraine</strong><br/>
                    Agent: UKRAINIAN AGENCY OF COPYRIGHT AND RELATED RIGHTS<br/>
                    Contact: Tatiana Boichenko - <a href="mailto:tatiana.boichenko@uacrr.org.ua">tatiana.boichenko@uacrr.org.ua</a> <br/>
                        Address: 44 Bohdan Khmelnytskyi Str. 01030, Kyiv, P.O. Box 146, Ukraine</p>

                    <p><strong>Japan</strong><br/>
                    Agent: Theatre Rights Ltd<br/>
                    Contact: Kaz Yoshida - <a href="mailto:kaz.yoshida@theatrerights.co.jp">kaz.yoshida@theatrerights.co.jp</a> <br/>
                        Address: 6-19-16-702, Jingumae, Shibuya-ku, Tokyo, 150-0001, Japan</p> */}
                    </StylePara>
                </div>
            </Wrapper>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                haveYouDunnit: file(relativePath: { eq: "Mousetrap_May21_D_Tel_330x262_AW.pdf_FINAL_AW_lowres.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 345, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <MarketingMaterials data={data}/>
        )}
    />
)
