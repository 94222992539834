import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media";
import ukMap from "../../images/uk-map.svg"
import globe from "../../images/globe.svg"
import countries from "../../images/countries.png"
import reviews from "../../images/speech-bubbles.svg"
import languages from "../../images/languages.svg"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import {Container, Row, Col} from "reactstrap";

const Wrapper = styled.div`
`

const Relative = styled.div`
    position: relative;
`
const Bg = styled.div`
    position: absolute;
    top: 60%;
    left: -25%;
    transform: translateY(-50%);
    z-index: 1;
    width: 150%;
    
    @media ${media.md} {
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
    }
`
const Content = styled.div`
    position: relative;
    z-index: 2;
`

const Flex = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const FlexItem = styled.div`
    width: 100%;
    padding: 1rem 0;
    
    @media ${media.md} {
        padding: 0 1rem;
        width: 50%;
    }
`
const QuoteWrapper = styled(Container)`
    padding-top: 2rem;
    .row {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
    h4 {
        font-family: ${props => props.theme.font.family.body};
    }
    
    p {
        font-family: ${props => props.theme.font.family.normal};
        color: #FFAA00;
    }
`

class InternationalSuccess extends Component {
    render() {
        return (
            <Wrapper>
                <div className="narrow mx-auto">
                    <p>THE MOUSETRAP is a proven commercial and artistic global success and one of the most profitable
                        theatre productions of all time, having played to visitors from around the world since 1952 in
                        London’s West End. </p>
                    <Flex>
                        <FlexItem>
                            <img src={ukMap} className="ukMap" alt="" title=""/>
                        </FlexItem>
                        <FlexItem>
                            <p>To mark its 60th anniversary in 2012, THE MOUSETRAP launched a UK tour which earned
                                excellent reviews and sold out a range of venues from intimate 400-seat theatres to the
                                magnificent Edinburgh Playhouse (the UK’s largest theatre, with over 3,000 seats). </p>
                        </FlexItem>
                    </Flex>
                </div>
                <Relative className="my-5">
                    <Bg>
                        <img src={languages} className="languages mx-auto d-block" alt="" title=""/>
                    </Bg>
                    <Content>
                        <div className="narrow mx-auto">
                            <p>In that same year, the show enjoyed 60 additional productions across the globe in a wide
                                variety of local languages.</p>

                            <p>Some territories have cast local stars to drive advance sales, as the West End production
                                did in 1952 with its initial casting of husband-and-wife team Richard Attenborough and
                                Sheila Sim. For the 60th Anniversary Gala performance in London, Patrick Stewart, Julie
                                Walters, Hugh Bonneville and others joined the show for one night only. But the play
                                itself is a star in its own right, and a reliance on big names to sell out has never
                                been necessary.</p>
                        </div>
                    </Content>
                </Relative>

                <Relative>
                    <Bg>
                        <Img fluid={this.props.data.woodBg.childImageSharp.fluid}/>
                    </Bg>
                    <Content>
                        <div className="py-4 py-md-5">
                            <img src={globe} className="globe mx-auto d-block" alt="" title=""/>
                        </div>
                        <div className="pt-4 pt-md-5">
                            <h3 className="text-center">PERFORMED IN DOZENS OF COUNTRIES, INCLUDING: </h3>
                        </div>
                        <img src={countries} className="countries mx-auto d-block" alt="" title=""/>
                    </Content>
                </Relative>
                <img src={reviews} className="reviews mx-auto d-block pt-4 pt-md-5" alt="" title=""/>
                <QuoteWrapper>
                    <Row>
                        <Col lg={6}>
                            <h4>“Vous aimez les pulls à motifs criards, les costumes en tartan, l’ambiance chaleureuse des pensions de famille? Vous savez tenir votre langue, même si l’envie de vendre la mèche vous brûle les lèvres? Aloes ce spectacle est fait pour vous!<br/>
                                Au suspense de rigueur, le spectacle ajoute une bonne dose d’humour, un soupçon de comedie musicale, une jovialite cocasse. C’est enlevé, réjouis sant, et cela enchante le public 7 à 77 ans.”</h4>
                            <p>Elle, December 2019</p>
                        </Col>

                        <Col className="pt-5 pt-lg-0" lg={6}>
                            <h4>“impérissable tradition britannique.<br/>
                            l'oeuvre à miraculeusement resiste au temps.<br/>
                            elle est la partie immuable de l'Angleterre.<br/>
                            nostalgie et exotisme sont donc les deux resorts de son success.<br/>
                                un grain de fantaisie, un peu de piment dans ce produit suivi et imperissable.”</h4>
                            <p>Le Figaro Philippe Tesson, September 2019</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <h4>“qu'on ait trouvé la solution de l'énigme avant la fin ou pas (pour les fans de polar, c’est possible), on sort le sourire aux lèvres de ce réjouissant piège assassin.“</h4>
                            <p>les Echos</p>
                        </Col>

                        <Col className="pt-5 pt-lg-0" lg={6}>
                            <h4>“Agatha Christie au coin du deu à la Pépinière<br/>
                                Un divertissement soigné et de qualité qui remplit sa mission”</h4>
                            <p>Yesterday at the theatre, September 2019</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <h4>“tant le spectacle est délicieusement retors et espiègle”</h4>
                            <p>Fille de Paname, January 2020</p>
                        </Col>

                        <Col className="pt-5 pt-lg-0" lg={6}>
                            <h4>“c’est sans soute la pièce la plus importante mais aussi las plus mythique de las “Reine du crime”.”</h4>
                            <p>Redaction Culture – France Televisions, September 2019</p>
                        </Col>
                    </Row>
                </QuoteWrapper>
            </Wrapper>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <InternationalSuccess data={data}/>
        )}
    />
)