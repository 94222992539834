import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media";
import inspector from "../../images/inspector.svg"
import characters from "../../images/characters.svg"
import runningTime from "../../images/running-time.svg"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

const Wrapper = styled.div`
`

const Relative = styled.div`
    position: relative;
`
const Bg = styled.div`
    position: absolute;
    top: 60%;
    left: -25%;
    z-index: 1;
    transform: translateY(-50%);
    width: 150%;
    width: 100%;
    
    @media ${media.md} {
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
    }
`
const Content = styled.div`
    position: relative;
    z-index: 2;
`

const Flex = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const FlexItem = styled.div`
    width: 100%;
    padding: 1rem 0;
    
    @media ${media.md} {
        padding: 0 1rem;
        width: 50%;
    }
`

class AboutthePlay extends Component {
    render() {
        return (
            <Wrapper>
                <div className="narrow mx-auto">
                    <Flex>
                        <FlexItem>
                            <img src={inspector} className="inspector" alt="" title=""/>
                        </FlexItem>
                        <FlexItem>
                            <h2>SYNOPSIS</h2>
                            <p>As the world’s benchmark for theatrical murder mystery, THE MOUSETRAP is deceptively simple in its formula. Its classic story has been imitated many times, but never surpassed.</p>
                            <p>As news spreads of a murder in London, a group of seven strangers find themselves snowed in at a countryside guesthouse. When a young detective arrives, the guests discover – to their horror – that the killer is in their midst!</p>
                            <p>As the plot twists unfold, the characters’ identities and backgrounds are revealed. Through to the play’s shocking climax, audiences remain on the edge of their seats as they try to figure out “whodunnit?”</p>
                        </FlexItem>
                    </Flex>
                </div>
                <div className="narrow mx-auto py-4 py-md-5">
                    <h2>CHARACTERS</h2>
                    <p>While there are certain traits the 8 actors must portray, it has become evident over 70 years of performances that Christie’s script and characterization allow for great flexibility of the interpretation and embodiment of each role. Innovative casting choices provide a way to bring depth, diversity and uniqueness to every production of THE MOUSETRAP.</p>
                </div>
                <div className="narrow mx-auto py-4 py-md-5">
                    <h3>8 Characters, In Order of Appearance: </h3>
                </div>
                <Relative>
                    <Bg>
                        <Img fluid={this.props.data.woodBg.childImageSharp.fluid}/>
                    </Bg>
                    <Content>

                        <div className="py-4 py-md-5">
                            <img src={characters} className="characters mx-auto d-block" alt="" title=""/>
                        </div>
                    </Content>
                </Relative>
                <img src={runningTime} className="runningTime mx-auto d-block" alt="" title=""/>
            </Wrapper>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <AboutthePlay data={data}/>
        )}
    />
)