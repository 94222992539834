import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media";
import coathanger from "../../images/coathanger.svg"
import doorway from "../../images/doorway.svg"
import set from "../../images/set.svg"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";


const Wrapper = styled.div`
`

const Relative = styled.div`
    position: relative;
`
const Bg = styled.div`
    position: absolute;
    top: 60%;
    left: -25%;
    transform: translateY(-50%);
    z-index: 1;
    width: 150%;
    
    @media ${media.md} {
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
    }
`
const Content = styled.div`
    position: relative;
    z-index: 2;
`

const Flex = styled.div`
    @media ${media.md} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const FlexItem = styled.div`
    width: 100%;
    padding: 1rem 0;
    
    @media ${media.md} {
        padding: 0 1rem;
        width: 50%;
    }
`

class ProductionDesign extends Component {
    render() {
        return (
            <Wrapper>
                <div className="narrow mx-auto py-4 py-md-5">
                    <Flex>
                        <FlexItem>
                            <h2>SET</h2>

                            <p>Although your team is encouraged to create their own design for the set, elements of the plot hinge on the story’s location. The look is that of an old, slightly shabby, English country home. At its simplest, THE MOUSETRAP calls for a single standing set with a number of windows and doorways. It can be built from scratch or from stock elements, should you have them available. UK productions have been presented on stages ranging from 8m x 5m to 15m x 15m, so you have the flexibility to make your production as grand or as modest as you need.</p>
                        </FlexItem>
                        <FlexItem>
                            <img src={set} className="set" alt="" title=""/>
                        </FlexItem>
                    </Flex>
                </div>
                <div className="narrow mx-auto py-4 py-md-5">
                    <Flex>
                        <FlexItem>
                            <img src={doorway} className="doorway" alt="" title=""/>
                        </FlexItem>
                        <FlexItem>
                            <p><strong>The Great Hall at Monkswell Manor, as described by Agatha Christie:</strong></p>

                            <p>There are tall windows Upstage Centre; a big arched opening Upstage Right leading to the entrance hall, the front door and the kitchen; and an arched opening Upstage Left leading upstairs to the bedrooms. Upstage Left leading off the stairs is the door to the library; Downstage Left is the door to the drawing room; Downstage Right is the door (opening on stage) to the dining room. Stage Right is an open fireplace, and beneath the window Upstage Centre is a window seat and a radiator.</p>
                        </FlexItem>
                    </Flex>
                </div>
                <Relative>
                    <Bg>
                        <Img fluid={this.props.data.woodBg.childImageSharp.fluid}/>
                    </Bg>
                    <Content>

                        <div className="py-4 py-md-5 narrow">
                            <img src={coathanger} className="coathanger mx-auto d-block" alt="" title=""/>
                            <p className="py-4">This is another area to incorporate new design ideas from your team. The London production uses modern designs with a distinct 1940s/1950s period style, but the play’s exact time period is described simply as “the Present” by Ms. Christie, leaving the costume design quite open to various interpretations.</p>
                        </div>
                    </Content>
                </Relative>
            </Wrapper>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                woodBg: file(relativePath: { eq: "wood-bg.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 85) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
		`}
        render={data => (
            <ProductionDesign data={data}/>
        )}
    />
)